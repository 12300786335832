import { ApiProperty } from '@nestjs/swagger'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { SchoolAffiliationStatus } from '../../transport-request'

@FormClass()
export class SchoolAffiliationStatusDTO {
    @ApiProperty({ example: 1 })
    @IsNumber()
    @IsNotEmpty()
    id: number

    @ApiProperty({ example: 'comment...' })
    @FormField({
        type: InputType.TEXTAREA,
        oneliner: true,
        rows: 1,
        placeholder: 'account.verification.schoolAffiliation.commentPlaceholder',
        resizable: false,
        mobileOneliner: true,
        compact: true,
        max: 1000,
        customClass: 'schoolAffiliationCommentWrapper',
    })
    @IsString()
    @IsOptional()
    schoolAffiliationNote: string

    // TODO: Validate enum (currently throws error)
    // @IsEnum(SchoolAffiliationStatus)
    @IsString()
    status: SchoolAffiliationStatus

    constructor(init: Partial<SchoolAffiliationStatusDTO>) {
        Object.assign(this, init)
    }
}
