import { IsArray, IsBoolean, IsEnum, IsOptional, IsString, ValidateNested } from 'class-validator'
import type {
    TransportRequestDecisionVisibility,
    TransportRequestPageConfiguration,
    TransportRequestTypeCards,
} from '../../transport-request/types'
import { AddressVerificationMethod } from '../../users'
import type { System } from '../entities'
import { Language, type CheckboxesConfigurationDto, type GeneralFrontendConfigurationDTO, type InfoBoxesDto, type SingleInfoBox } from '../types'

export class CustomerConfigurationDto {
    @ValidateNested()
    generalFrontendConfiguration: GeneralFrontendConfigurationDTO

    @IsEnum(AddressVerificationMethod)
    addressVerificationMethod: AddressVerificationMethod

    @ValidateNested()
    transportRequestTypeCards: TransportRequestTypeCards

    @ValidateNested()
    transportRequestPageConfiguration: TransportRequestPageConfiguration

    @ValidateNested()
    @IsOptional()
    transportRequestDecisionVisibility?: TransportRequestDecisionVisibility

    @IsOptional()
    @ValidateNested()
    infoBoxes?: InfoBoxesDto

    @IsOptional()
    @ValidateNested()
    bankAccountLegalText?: SingleInfoBox

    @IsOptional()
    guardianTypes?: System[]

    @IsOptional()
    @IsString()
    logo?: string

    @IsOptional()
    @ValidateNested()
    checkboxesConfiguration?: CheckboxesConfigurationDto

    @IsString()
    tenantKey: string

    @IsArray()
    @IsEnum(Language, { each: true })
    @IsOptional()
    enabledLanguages?: Language[]

    @IsBoolean()
    guardianBirthdayOptional: boolean
}
