import { Type } from 'class-transformer'
import { IsBoolean, IsEnum, IsOptional, IsString } from 'class-validator'
import { DmsParams } from './DmsParams'
import { FooterType } from './FooterType.enum'
import { HeaderType } from './HeaderType.enum'
import type { HTML } from './Html'

type millimeter = number

// TODO: Revise if other options are possible
export class CreatePrintJobDto extends DmsParams {
    @IsString() filename: string
    @IsString() instance: string
    body: HTML

    top: millimeter
    bottom: millimeter
    left: millimeter
    right: millimeter

    @Type(() => Number)
    @IsEnum(HeaderType)
    @IsOptional()
    headerType: number

    @IsOptional()
    headerHtml: HTML

    @Type(() => Number)
    @IsEnum(FooterType)
    @IsOptional()
    footerType: number // Currently named `print_footer`

    @IsOptional()
    footerHtml?: HTML

    landscape: boolean
    dpi?: number
    css: string
    baseUrl: string

    pageWidth?: number
    pageHeight?: number

    // parameter to identify a set of files together
    @IsOptional() @IsString() jobId?: string | null

    // params for the S3 storage
    @IsOptional() @IsBoolean() s3Upload?: boolean | null
    @IsOptional() @IsString() s3StoragePath?: string | null
}
