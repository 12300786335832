import { parseISO, format } from 'date-fns'

export function formatDate(dateString): string {
    const parsedDate = parseISO(dateString)
    const formattedDate = format(parsedDate, 'dd.MM.yyyy')
    return formattedDate
}

export function isWithinFourMonths(endDate: Date): boolean {
    const currentDate = new Date()
    const fourMonthsAgo = new Date()
    fourMonthsAgo.setMonth(currentDate.getMonth() - 4)
    fourMonthsAgo.setHours(0, 0, 0, 0)
    return new Date(endDate) >= fourMonthsAgo
}
