import { ApiProperty } from '@nestjs/swagger'
import { FormClass, FormField, InputType } from '@stadtlandnetz/forms'
import { IsEmail, IsEmpty, IsNotEmpty, ValidateIf } from 'class-validator'
import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm'
import { TripRequirement } from '../../routing'
import { WaypointStudent } from '../../tours'
import { TransportRequest } from '../../transport-request'
import { BaseUser } from '../../users/entities/BaseUser.entity'
import { Gender, VariableFields } from '../../utils'
import { pipeListTransformer } from '../../utils/transformers'

export type StudentID = number
export enum StudentValidation {
    EMAIL_REQUIRED = 'email_required'
}

@Entity('tbl_schueler', { schema: 'via' })
@FormClass({ interfereLabels: true, defaultGroupName: 'requests.type1.student.title' })
export class Student {
    @ApiProperty({ type: Number })
    @PrimaryGeneratedColumn({ type: 'int', name: 'id', unsigned: true })
    id: StudentID

    // this can be with letters as well
    @ApiProperty({ type: String })
    @Index({ unique: true })
    @Column('varchar', { name: 'snr', length: 100 })
    studentNumber: string

    // @Column( { name: 'geschlecht', nullable: true, type: 'enum', enum: StudentSex })
    @FormField({
        type: InputType.SELECT,
        optional: true,
        options: [
            {
                title: '$male',
                value: 'männlich'
            },
            {
                title: '$female',
                value: 'weiblich'
            },
            {
                title: '$other',
                value: 'divers'
            }
        ],
        title: 'gender'
    })
    @Column({ name: 'geschlecht', nullable: true, type: 'varchar', length: 10 })
    sex: Gender | null

    @FormField({ placeholder: 'Peter', title: 'firstname' })
    @IsNotEmpty({ always: true })
    @Column('varchar', { name: 'vorname', length: 100 })
    firstName: string

    @FormField({ placeholder: 'Lustig', title: 'lastname' })
    @IsNotEmpty({ always: true })
    @Column('varchar', { name: 'nachname', length: 100 })
    lastName: string

    @FormField({ placeholder: '01.01.2020', type: InputType.DATE })
    @IsNotEmpty({ always: true })
    @Column('date', {
        name: 'gb_date',
        nullable: true
    })
    birthday: Date | null

    @FormField({ placeholder: 'Stadt', optional: true })
    @Column('varchar', { name: 'geburtsort', length: 100, nullable: true })
    birthplace: string | null

    @FormField({ title: 'mail', optional: true, max: 100 })
    @ValidateIf((_, value) => !!value)
    @IsEmail({}, { groups: [StudentValidation.EMAIL_REQUIRED] })
    @Column('varchar', { name: 'email', length: 255, nullable: true })
    email: string

    @Column('varchar', { name: 'lk', length: 100, nullable: true })
    county: string | null

    @Column('text', { name: 'attribute', nullable: true, transformer: pipeListTransformer })
    attributes: string[]

    // this is just an array of strings
    @Column('text', { name: 'merkmale', nullable: true })
    features: string | null

    @Column('text', { name: 'bemerkungen', nullable: true })
    notes: string | null

    @Column((type) => VariableFields)
    variableFields: VariableFields

    @Column('date', {
        name: 'anlage_date',
        nullable: true
    })
    createdAt: Date | null

    @Column('varchar', { name: 'passbildFilename', length: 255, nullable: true })
    @IsEmpty({ always: true })
    passportImage: string | null

    @ApiProperty({ type: () => BaseUser })
    @ManyToOne(() => BaseUser, (user) => user.students)
    @JoinColumn()
    user: BaseUser

    @OneToMany((type) => TransportRequest, (request) => request.student)
    requests: TransportRequest[]

    @OneToMany((type) => WaypointStudent, (waypoints) => waypoints.student)
    waypoints: WaypointStudent[]

    @OneToMany((type) => TripRequirement, (requirement) => requirement.student)
    requirements: TripRequirement[]

    constructor(init?: Partial<Student>) {
        Object.assign(this, init)
    }
}
