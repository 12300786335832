export enum InputType {
    TEXT = 'text',
    TEXTAREA = 'textarea',
    PASSWORD = 'password',
    EMAIL = 'email',
    NUMBER = 'number',
    PIN = 'pin',
    PHONE = 'tel',
    DATE = 'date',
    DATETIMELOCAL = 'datetimelocal', // Only supported in Chrome & Edge
    TIME = 'time',
    RANGE = 'range',
    SELECT = 'select',
    MULTISELECT = 'multiselect',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    COLOR = 'color',
    AUTOCOMPLETE = 'autocomplete',
    FILE = 'file',
    DROPDOWNSEARCH = 'dropdownsearch'
}

export interface FieldOptions {
    type?: InputType
    class?: string
    value?: string
    placeholder?: string
    min?: number | string
    endDate?: number | string
    max?: number | string
    step?: number
    disabled?: boolean
    readonly?: boolean
    items?: object[]
    label?: string
    group?: string
    multiple?: boolean
    validation?: Validator[]
    column?: number
    rows?: number
    resizable?: boolean
    oneliner?: boolean
    title?: string
    options?: object[]
    optional?: boolean
    search?: boolean
    style?: string
    customClass?: string
    mobileOneliner?: boolean
    compact?: boolean
    hidden?: boolean
    resettable?: boolean
    autocomplete?: string
}

export type FormComponentRegistry = {
    [key in InputType]?: object
}

export interface FormMetadata {
    target: Function
    options: FormOptions
}

export interface FormOptions {
    interfereLabels?: boolean
    defaultGroupName?: string
}

export type FormGroup = {
    name: string
    nested: string
    fields: Fields
}

export type FormGroupContext = {
    getGroup: () => FormGroup
    setFields: (groupFields: any) => void
    getData: () => any
    setData: (data: any) => void
    getErrors: () => SimpleErrors
    setRelevantError: (data: any) => any
}

export interface FieldMetadata {
    target: Function // Constructor of the data class
    propertyName: string
    options: FieldOptions
}

export type FieldName = string

export interface Field extends FieldOptions {
    name: FieldName
    id: string
    type: InputType
    nested?: string | boolean
}

export type Fields = Field[]

export type FieldContext = {
    getField: () => Field
    getValue: () => any
    setValue: (value: any) => any
    setTouched: (touched: any) => any
    addFieldName: (fieldName: any) => void
}

export type DataClass = new (init: object) => object

// export type Validator = (value: unknown) => string
type Validator = (validationOptions?: any) => PropertyDecorator

export type SimpleErrors = {
    [key: string]: string[]
}

export type FormCardOptionValue = {
    name: string
    value: unknown
}

export type OptionsUpdateFunction<T> = (value: T) => SelectOption[] | Promise<SelectOption[]>
export type OptionsUpdate<T> = {
    runOn: string[] | null
    function: OptionsUpdateFunction<T>
}

export type DynamicFieldOptions<T> = {
    propertyName: string
    dynamicValues?: (SelectOption | DropdownSearchOption)[] | OptionsUpdateFunction<T>
    readonly?: (dto: T) => boolean
    runOn?: FieldName[]
    options?: { [K in keyof FieldOptions]: FieldOptions[K] | ((dto: T) => FieldOptions[K]) }
}[]

// TODO: Make the form renderer accept this as a prop, not FormCardOptions
export type FormCardOptionsMap<T> = Record<keyof T, (SelectOption | DropdownSearchOption)[]>

/**
 * Represents the progress the user has made in a specific form group.
 *
 * This information is calculated by the form and can be used to display a summary of the overall progress.
 * TODO: Merge with the similar enum in `@stadtlandnetz/design`
 */
export enum FormGroupStatus {
    Empty = 'empty',
    Success = 'success',
    Incomplete = 'incomplete',
    InProcess = 'in_process',
    Error = 'error'
}

export enum SectionName {
    STUDENT = 'student',
    ADDRESS = 'address',
    SIBLINGS = 'siblings',
    GUARDIANS = 'guardians',
    SCHOOL = 'school',
    TRANSPORT = 'transport',
    FOOTPATH = 'footpath',
    BANKACCOUNT = 'bankAccount',
    ORDERDATE = 'orderDate',
    UPLOAD = 'upload',
    PASSPORT = 'passport',
    NOTES = 'notes',
    REQUEST_VARIABLE_FIELDS = 'requestVariableFields',
    REFUND_VARIABLE_FIELDS = 'refundVariableFields'
}

export type FormSection = {
    name: string
    state: FormGroupStatus
    in_process: boolean
    order?: number
    options?: Record<any, any>
}

export type FormProgress = {
    [key in SectionName]?: FormSection
}

export type SelectOption<T = any> = {
    title: string
    value: T
}

export type DropdownSearchOption<T = unknown> = SelectOption<T> & {
    subtitle: string
}
